import React, { FunctionComponent } from "react";

interface FacebookProps {
  width: number;
  height: number;
  className?: string;
  color?: string;
}

const Instagram: FunctionComponent<FacebookProps> = ({ className, color, width, height }: FacebookProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			zoomAndPan="magnify"
			viewBox="0 0 52.5 52.499999"
			height={height}
			preserveAspectRatio="xMidYMid meet"
			version="1.0"
			className={className}
		>
			<defs>
				<clipPath id="bd5f1ebd06">
					<path d="M 0 0 L 52.003906 0 L 52.003906 52.003906 L 0 52.003906 Z M 0 0 " clipRule="nonzero" />
				</clipPath>
				<clipPath id="2fc55eb1d7">
					<path
						d="M 12.296875 12.296875 L 39.785156 12.296875 L 39.785156 39.785156 L 12.296875 39.785156 Z M 12.296875 12.296875 "
						clipRule="nonzero"
					/>
				</clipPath>
			</defs>
			<g clipPath="url(#bd5f1ebd06)">
				<path
					fill={color}
					d="M 26.003906 0 C 11.636719 0 0 11.648438 0 26.003906 C 0 40.355469 11.636719 52.003906 26.003906 52.003906 C 40.367188 52.003906 52.003906 40.355469 52.003906 26.003906 C 52.003906 11.648438 40.367188 0 26.003906 0 Z M 26.003906 50.359375 C 12.550781 50.359375 1.644531 39.453125 1.644531 26.003906 C 1.644531 12.550781 12.550781 1.644531 26.003906 1.644531 C 39.453125 1.644531 50.359375 12.550781 50.359375 26.003906 C 50.359375 39.453125 39.453125 50.359375 26.003906 50.359375 Z M 26.003906 50.359375 "
					fillOpacity="1"
					fillRule="nonzero"
				/>
			</g>
			<path
				fill={color}
				d="M 30.910156 26 C 30.910156 26.320312 30.878906 26.640625 30.816406 26.957031 C 30.753906 27.273438 30.660156 27.582031 30.539062 27.878906 C 30.414062 28.175781 30.261719 28.460938 30.082031 28.726562 C 29.90625 28.996094 29.699219 29.246094 29.472656 29.472656 C 29.246094 29.699219 28.996094 29.90625 28.726562 30.082031 C 28.460938 30.261719 28.175781 30.414062 27.878906 30.539062 C 27.582031 30.660156 27.273438 30.753906 26.957031 30.816406 C 26.640625 30.878906 26.320312 30.910156 26 30.910156 C 25.675781 30.910156 25.355469 30.878906 25.039062 30.816406 C 24.722656 30.753906 24.417969 30.660156 24.117188 30.539062 C 23.820312 30.414062 23.539062 30.261719 23.269531 30.082031 C 23 29.90625 22.753906 29.699219 22.523438 29.472656 C 22.296875 29.246094 22.09375 28.996094 21.914062 28.726562 C 21.734375 28.460938 21.582031 28.175781 21.460938 27.878906 C 21.335938 27.582031 21.242188 27.273438 21.179688 26.957031 C 21.117188 26.640625 21.085938 26.320312 21.085938 26 C 21.085938 25.675781 21.117188 25.355469 21.179688 25.039062 C 21.242188 24.722656 21.335938 24.417969 21.460938 24.117188 C 21.582031 23.820312 21.734375 23.539062 21.914062 23.269531 C 22.09375 23 22.296875 22.753906 22.523438 22.523438 C 22.753906 22.296875 23 22.09375 23.269531 21.914062 C 23.539062 21.734375 23.820312 21.582031 24.117188 21.460938 C 24.417969 21.335938 24.722656 21.242188 25.039062 21.179688 C 25.355469 21.117188 25.675781 21.085938 26 21.085938 C 26.320312 21.085938 26.640625 21.117188 26.957031 21.179688 C 27.273438 21.242188 27.582031 21.335938 27.878906 21.460938 C 28.175781 21.582031 28.460938 21.734375 28.726562 21.914062 C 28.996094 22.09375 29.246094 22.296875 29.472656 22.523438 C 29.699219 22.753906 29.90625 23 30.082031 23.269531 C 30.261719 23.539062 30.414062 23.820312 30.539062 24.117188 C 30.660156 24.417969 30.753906 24.722656 30.816406 25.039062 C 30.878906 25.355469 30.910156 25.675781 30.910156 26 Z M 30.910156 26 "
				fillOpacity="1"
				fillRule="nonzero"
			/>
			<g clipPath="url(#2fc55eb1d7)">
				<path
					fill={color}
					d="M 35.367188 12.296875 L 16.628906 12.296875 C 14.238281 12.296875 12.296875 14.238281 12.296875 16.628906 L 12.296875 35.363281 C 12.296875 37.753906 14.238281 39.695312 16.628906 39.695312 L 35.363281 39.695312 C 37.753906 39.695312 39.695312 37.753906 39.695312 35.363281 L 39.695312 16.628906 C 39.703125 14.238281 37.757812 12.296875 35.367188 12.296875 Z M 26 33.496094 C 21.863281 33.496094 18.5 30.136719 18.5 26 C 18.5 21.863281 21.863281 18.5 26 18.5 C 30.136719 18.5 33.496094 21.863281 33.496094 26 C 33.496094 30.136719 30.136719 33.496094 26 33.496094 Z M 33.753906 19.535156 C 33.042969 19.535156 32.460938 18.957031 32.460938 18.242188 C 32.460938 17.527344 33.042969 16.949219 33.753906 16.949219 C 34.46875 16.949219 35.046875 17.527344 35.046875 18.242188 C 35.046875 18.957031 34.46875 19.535156 33.753906 19.535156 Z M 33.753906 19.535156 "
					fillOpacity="1"
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export default Instagram;
